export type VindiPaymentType = 'CREDIT_CARD' | 'BANK_SLIP' | 'PIX'

export interface VindiCreditCardInformation {
  vindiPaymentProfileId: number,
  brand: string,
  displayNumber: string,
  bin?: string,
  holderName: string,
  month: number,
  year: number
}

export interface VindiTokenizationRequest {
  holderName: string,
  cardNumber: string,
  cardExpiration: string,
  cardCVV: string,
  paymentCompanyCode: string
}

export interface VindiTokenizationResponse {
  gatewayToken: string
}

interface VindiApiTokenizationResponse {
  'payment_profile': {
    'gateway_token': string
  }
}

export interface VindiBillingAddress {
  zipCode?: string,
  street?: string,
  number?: string,
  complement?: string,
  city?: string,
  district?: string,
  state?: string
}

export interface CreateVindiPaymentProfileRequest {
  customerId: number,
  billingAddress?: VindiBillingAddress,
  paymentProfileType: string,
  creditCardToken?: string
}

export interface CreateSelfVindiPaymentProfileRequest {
  billingAddress?: VindiBillingAddress,
  paymentProfileType: string,
  creditCardToken?: string
}

export interface UpdateVindiPaymentProfileRequest {
  paymentProfileType: string,
  creditCardToken?: string
}

export interface VindiPaymentProfile {
  tenant: string,
  billingPlatformId: number,
  customerId: number,
  paymentProfileId: string,
  vindiCustomerId: number,
  vindiPaymentType: VindiPaymentType,
  vindiBillingAddress: VindiBillingAddress,
  vindiCreditCardInformation?: VindiCreditCardInformation,
  createdAt: number,
  updatedAt: number
}

export default {
  async tokenizeCreditCard(request: VindiTokenizationRequest): Promise<VindiTokenizationResponse> {
    const { vindi: vindiConfig } = useConfig()
    const authorization = btoa(`${vindiConfig.publicKey}:`)

    const response =
      await $fetch<VindiApiTokenizationResponse>(`${vindiConfig.apiAddress}/api/v1/public/payment_profiles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${authorization}`
        },
        body: JSON.stringify({
          'holder_name': request.holderName,
          'card_number': request.cardNumber,
          'card_expiration': request.cardExpiration,
          'card_cvv': request.cardCVV,
          'payment_company_code': request.paymentCompanyCode,
          'payment_method_code': 'credit_card'
        })
      })

    return {
      gatewayToken: response['payment_profile']['gateway_token'],
    }
  },
  async createPaymentProfile(billingPlatformId: number, request: CreateVindiPaymentProfileRequest): Promise<VindiPaymentProfile> {
    return apiPost<VindiPaymentProfile>(`/billing/${billingPlatformId}/vindi/payment-profile`, { body: request })
  },
  async createSelfPaymentProfile(billingPlatformId: number, request: CreateSelfVindiPaymentProfileRequest): Promise<VindiPaymentProfile> {
    return apiPost<VindiPaymentProfile>(`/self/billing/${billingPlatformId}/vindi/payment-profile`, { body: request })
  },
  async updatePaymentProfile(billingPlatformId: number, paymentProfileId: string, request: UpdateVindiPaymentProfileRequest): Promise<VindiPaymentProfile> {
    return apiPatch<VindiPaymentProfile>(`/billing/${billingPlatformId}/vindi/payment-profile/${paymentProfileId}`, { body: request })
  }
}
