<template lang="pug">
.credit-card

  form(@submit.prevent="submit")
    .form-line
      SelectInput(
        label="Bandeira",
        v-model="state.cardIssuer",
        @update:modelValue="emitUpdate",
        @blur="v$.cardIssuer.$touch",
        :errors="v$.cardIssuer.$errors",
        style="flex-grow: 1",
        autocomplete="cc-type")
        option(value="", label="")
        option(value="mastercard", label="Mastercard") Mastercard
        option(value="visa", label="Visa") Visa
        option(value="elo", label="Elo") Elo
        option(value="american_express", label="Amex") Amex
        option(value="hipercard", label="Hipercard") Hipercard
        option(value="diners_club", label="Diners") Diners

      TextInput(
        label="Número do cartão",
        v-model="state.cardNumber",
        :mask="maskCardNumber",
        @update:modelValue="emitUpdate",
        @blur="v$.cardNumber.$touch",
        :errors="v$.cardNumber.$errors",
        style="flex-grow: 4; min-width: 260px",
        autocomplete="cc-number")

    .form-line
      TextInput(
        label="Data de exp.",
        v-model="state.cardExpiration",
        :mask="maskCardExpiration",
        @update:modelValue="emitUpdate",
        @blur="v$.cardExpiration.$touch",
        :errors="v$.cardExpiration.$errors",
        autocomplete="cc-exp")
      TextInput(
        label="CVV",
        v-model="state.cardVerificationCode",
        :mask="maskCardVerificationCode",
        @update:modelValue="emitUpdate",
        @blur="v$.cardVerificationCode.$touch",
        :errors="v$.cardVerificationCode.$errors",
        autocomplete="cc-csc")

    .form-line
      TextInput(
        label="Nome",
        v-model="state.cardHolderName",
        :mask="cardHolderNameMask",
        @update:modelValue="emitUpdate",
        @blur="v$.cardHolderName.$touch",
        :errors="v$.cardHolderName.$errors",
        style="flex-grow: 2",
        autocomplete="cc-name")
      SelectInput(
        v-if="showInstallmentsOption",
        label="Parcelamento",
        v-model="state.installments",
        @update:modelValue="emitUpdate",
        @blur="v$.installments.$touch",
        :errors="v$.installments.$errors",
        style="flex-grow: 1")
        option(:value="1", label="À vista") À vista
        option(:value="2", label="2x sem juros") 2x sem juros
        option(:value="3", label="3x sem juros") 3x sem juros

    button(v-show="false", type="submit")

</template>
<script setup lang="ts">
import type { PropType } from 'vue'

import useVuelidate from '@vuelidate/core'

import { maskCardNumber, maskCardExpiration, maskCardVerificationCode, maxLengthMask } from '~/scripts/masks/masks'
import { required, maxLength } from '~/scripts/validators/validators'

export interface CreditCardInformation {
  cardIssuer: string,
  cardNumber: string,
  cardExpiration: string,
  cardVerificationCode: string,
  cardHolderName: string,
  installments: number
}

const props = defineProps({
  modelValue: {
    type: Object as PropType<CreditCardInformation>,
    required: true,
  },
  showInstallmentsOption: {
    type: Boolean,
    default: false
  },
  onSubmit: {
    type: Function as PropType<() => Promise<void>>,
  }
})

const emit = defineEmits(['update:modelValue'])

const state = reactive(props.modelValue)

const rules = {
  cardIssuer: { required },
  cardNumber: { required },
  cardExpiration: { required },
  cardVerificationCode: { required },
  cardHolderName: { required, maxLength: maxLength(120) },
  installments: { required },
}

const submit = () => {
  if (props.onSubmit) {
    props.onSubmit()
  }
}

const cardHolderNameMask = maxLengthMask(120)

const v$ = useVuelidate(rules, state)

const validate = async () => {
  return await v$.value.$validate()
}

const emitUpdate = () => {
  emit('update:modelValue', state)
}

defineExpose({
  validate
})
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.credit-card
  position: relative
  display: block
  margin: 0 auto
  max-width: 580px
  text-align: left

  .form-line
    position: relative
    display: flex
    gap: 0 8px
    flex-wrap: wrap

    *
      width: 90px
      flex-shrink: 1
      flex-grow: 1


</style>